<template>
  <div class="header">
    <div class="header-button">
      <a-button
        type="primary"
        @click="toggleCollapsed"
        style="margin-bottom: 16px"
        size="small"
      >
        <MenuUnfoldOutlined v-if="collapsed" />
        <MenuFoldOutlined v-else />
      </a-button>
    </div>
    <div class="header-menu">
      <a-dropdown>
        <a class="ant-dropdown-link" @click.prevent>
          <a-avatar size="small" :src="userInfo.avatar"> </a-avatar>
          {{ userInfo.nickname }}
        </a>
        <template #overlay>
          <a-menu theme="light" @click="onClick">
            <a-menu-item key="0">
              <template #icon>
                <AuditOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
              <span>个人中心</span>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="1">
              <template #icon>
                <SettingOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
              <span>修改密码</span>
            </a-menu-item>
            <a-menu-divider />
            <a-menu-item key="2">
              <template #icon>
                <LogoutOutlined style="color: rgba(0, 0, 0, 0.25)" />
              </template>
              <span>退出系统</span>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
  </div>
</template>
<script>
import {
  LogoutOutlined,
  SettingOutlined,
  AuditOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
import store from "@/store";
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  createVNode,
  watch,
} from "vue";
import { Modal } from "ant-design-vue";
export default defineComponent({
  name: "Header",
  components: {
    LogoutOutlined,
    SettingOutlined,
    AuditOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
  },
  props: {
    userInfo: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const state = reactive({
      collapsed: false,
    });
    const { emit } = getCurrentInstance();

    const toggleCollapsed = () => {
      state.collapsed = !state.collapsed;
      emit("collapsed");
    };

    const onClick = (e) => {
      if (e.key == 2) {
        LogoutConfirm();
      } else if (e.key == 1) {
        store.dispatch("clickMenuItem", "/pwd");
      } else {
        store.dispatch("clickMenuItem", "/usercenter");
      }
    };

    const LogoutConfirm = () => {
      Modal.confirm({
        title: "您确定要注销登录吗?",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "确认",
        cancelText: "取消",
        // content: createVNode(
        //   "div",
        //   { style: "color:red;" },
        //   "注销后所有缓存信息将会丢失"
        // ),
        onOk() {
          store.dispatch("LogoutResult");
        },
        onCancel() {
          console.log("Cancel");
        },
      });
    };
    watch(
      () => [props.userInfo],
      ([nv1]) => {
        state.userInfo = nv1;
      },
      {
        immediate: true,
        deep: true,
      }
    );
    return {
      ...toRefs(state),
      toggleCollapsed,
      onClick,
      LogoutConfirm,
    };
  },
});
</script>
<style lang="scss" scoped>
.header {
  padding: 0px 20px;
  height: 48px;
}
.header-menu {
  float: right;
  // &-menu {
  //   border: 1 solid red;
  //   border-radius: 10px;
  //   box-shadow: 0 2px 8px 0 rgba(7, 17, 27, 0.06);
  // }
}
.header-button {
  float: left;
}
</style>